interface PartialResource {
  assets?: {
    publicationDate: string;
  }[];
}

/**
 * @description Return the latest publication date, as a UTC date string, of all the assets attached to the resources
 *
 * TODO: Format the returned string in line with browser preferences
 */
export function getLatestPublicationDate(resources: PartialResource[]): string {
  return (
    resources
      .reduce(
        (acc, resource) => [...acc, ...(resource?.assets || []).map(({ publicationDate }) => publicationDate)],
        [] as string[]
      )
      .sort()
      .reverse()[0] || ''
  );
}
